import {Button, ButtonVariantProps} from "@varymadellc/my-weed-club-component-library";
import React from "react";

export interface ScenePlayerBackButtonProps {
    setScene: (scene: string) => void;
    setFrame: (frame: number) => void;
}

export default function getScenePlayerBackButtonFactory({setScene, setFrame}: ScenePlayerBackButtonProps) {
    return function getScenePlayerBackButton({scene, frame = 1}) {
        return (
            <Button variant={'link' as ButtonVariantProps.link} type="button" onClick={() => {
                setScene(scene);
                setFrame(frame);
            }}>Back</Button>
        );
    };
}

