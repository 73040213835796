import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useState} from "react";
import {Button, ButtonVariantProps} from "@varymadellc/my-weed-club-component-library";
import {useAuth} from "@/providers/AuthProvider";
import Link from "next/link";

const signinValidation = (values: any) => {
    const errors: any = {};

    ['email', 'password',].forEach((name) => {
        if (!values[name] || values[name].length < 1) {
            errors[name] = 'Required';
        }
    });

    return errors;
};

export default function SignInForm() {
    const {signIn} = useAuth();

    const [signinError, setSigninError] = useState<null | string>(null);

    const signinSubmit = async (
        values: any,
    ) => {
        if (signinError) {
            setSigninError(null);
        }

        await signIn(values, (error?: string) => {
            if (error) {
                setSigninError(error);
                return;
            }

            window.location.href = '/self/profile';
        });
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validate={signinValidation}
            onSubmit={signinSubmit}>
            {({isSubmitting}) => (
                <Form className="text-start min-h-[300px] p-6">
                    {signinError && <div className="p-2 text-red-500">{
                        signinError
                    }</div>}
                    <div className="mb-3">
                        <label htmlFor="email"
                               className="form-label">Email</label>
                        <Field className="rounded w-full bg-gray-700 text-white p-1"
                               type="text"
                               name="email"/>
                        <div className="h-6">
                            <ErrorMessage name="email" className="text-red-500"
                                          component="div"/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="password"
                               className="form-label">Password</label>
                        <Field className="rounded w-full text-white bg-gray-700 p-1"
                               type="password"
                               name="password"/>
                        <div className="h-6">
                            <ErrorMessage name="email" className="text-red-500"
                                          component="div"/>
                        </div>
                    </div>
                    <div className="mx-auto w-full text-center">
                        <Button className="border-2 border-primary text-white px-4"
                                variant={'link' as ButtonVariantProps.link} type="submit"
                                disabled={isSubmitting}>Access</Button>
                    </div>
                    <p className="text-center text-sm mt-4"><Link className="btn btn-link"
                                                                  href="/forgot-password">Forgot
                        Password</Link></p>
                </Form>
            )}
        </Formik>
    );
}