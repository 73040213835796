import React, {useState} from "react";
import {Button, Input} from "@varymadellc/my-weed-club-component-library";
import {useAuth} from "@/providers/AuthProvider";
import {useRouter} from "next/navigation";
import AccessForm from "@/components/scene/AccessForm";
import Link from "next/link";
import Page from "@/layout/Page";

export default function Index() {
    const {isValidToken} = useAuth();

    const navigate = useRouter();

    if (isValidToken()) {
        navigate.push('/self/profile');
        return;
    }

    function QueueAccessCodeRedirect() {
        const [code, setCode] = useState('');

        return (
            <form className="flex-row m-1" onSubmit={(event) => {
                event.preventDefault();

                const path = `${window.location.protocol}//${window.location.host}/q/code/${code}`;

                navigate.push(path);
            }}>
                <Input onChange={({target}) => setCode(target.value)} type="text" placeholder="code" className="flex-[3]"/>
                <Button className="rounded-l-none flex-1" type="submit">Go!</Button>
            </form>
        );
    }

    return (
        <Page menu={<QueueAccessCodeRedirect/>}>
            <div className="relative overflow-hidden w-full flex flex-col justify-center p-2 rounded-xl"
                 style={{
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                     backgroundImage: 'url(/imgs/home-splash.png)'
                 }}>
                <div className="lg:mt-[80px] w-12/12 mx-auto mt-[40px] mb-10 md:mb-20 relative z-0 min-h-[25px]">
                    <h2 className="capitalize">An online social cannabis club</h2>
                </div>
                <div className="w-10/12 flex flex-col justify-center items-center mx-auto gap-20">
                    <div
                        className="flex-1 flex flex-col bg-black justify-center items-center border-t-4 border-primary min-h-[380px] p-4">
                        <p>🌿 Welcome to our vibrant online cannabis community! 🌿</p>
                        <p>Are you ready to embark on a journey where like-minded individuals gather to share, connect,
                            and celebrate the wonderful world of cannabis? Look no further! Our platform is your digital
                            oasis, a place where enthusiasts, connoisseurs, and those curious about cannabis come
                            together in harmony.</p>
                        <p>👋 Join us and discover a space that&apos;s more than just a website; it&apos;s a
                            cannabis-lover&apos;s home. 🚀 Here, you&apos;ll find community, informative discussions, and
                            an virtual lounge—all in a safe and responsible environment. 🌱 Don&apos;t miss out on the
                            fun! Sign up today, and let&apos;s explore the limitless possibilities of cannabis culture
                            together! 🎉</p>
                        <p className="bg-black/80 rounded p-1"><Link className="underline pointer text-2xl" href="/signup/brands">Are you a brand? Read here.</Link>
                        </p>
                    </div>
                    <div
                        id="signinForm"
                        className="w-full flex-1 flex flex-col mx-auto lg:flex-row bg-black bg-semi-transparent border-t-4 border-primary min-h-[380px]">
                        <AccessForm/>
                    </div>
                </div>
                <div className="bg-black/80 w-8/12 mx-auto m-10 p-10 rounded text-center">
                    <p>We are a club for the cannabis community, and you&apos;re invited. It&apos;s free, so join
                        now!</p>
                    <br/>
                    <small>Absolutely no sales/buying on or associated with MyWeed.Club.</small>
                </div>
            </div>
        </Page>
    );
}

export async function getServerSideProps() {
    return {
        props: {
            showFooter: false,
        }
    }
}