import React, {ReactElement} from "react";
import cx from "classnames";
import Head from "next/head";

export interface PageProps {
    children: ReactElement | ReactElement[];
    className?: string;
    menu?: ReactElement | null;
    title?: string | ReactElement;
    removePadding?: boolean;
}

export default function Page({children, className, menu, title, removePadding = false}: PageProps) {
    return (
        <div className={cx("flex flex-col w-full p-2 relative", {"!p-0": removePadding}, className)}>
            <Head>
                <title>{typeof title === 'string' ? title : `MyWeed.Club ${typeof window !== 'undefined' ? window?.location?.pathname : ''}`}</title>
            </Head>
            {
                (title || menu) &&
                <div className="flex flex-row pb-5 gap-4 relative z-10000">
                    <div className="flex flex-1 z-[2] justify-start items-center">
                        {title && typeof title === 'string' ? <h1 className="inline-block">{title}</h1> : title}
                    </div>
                    <div className="relative flex-[2] z-[10000] flex justify-end overflow-y-hidden">
                        {menu}
                    </div>
                </div>
            }
            <div className={cx('flex flex-col gap-2 relative z-[9]')}>
                {children}
            </div>
        </div>
    );
}