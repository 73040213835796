import React from "react";
import {Button, ButtonSizeProps} from "@varymadellc/my-weed-club-component-library";
import {useRouter} from "next/router";

export interface IntroSceneProps {
    setScene: (scene: string) => void;
    setFrame: (frame: number) => void;
}

export const SCENE_SIGN_UP = 'sign-up';

export const SCENE_SIGN_IN = 'sign-in';

export default function IntroScene({setScene, setFrame}: IntroSceneProps) {
    const navigate = useRouter();

    return (
        <div className="w-full flex flex-col justify-center items-center px-4 py-10">
            <Button size={'small' as ButtonSizeProps.small} className="px-10 py-5 bg-black w-full rounded mb-2"
                    onClick={() => {
                        setScene(SCENE_SIGN_IN)
                    }}>Login
            </Button>
            <br/>
            <Button size={'small' as ButtonSizeProps.small} className="px-10 py-5 bg-black w-full rounded m-2"
                    onClick={() => {
                        gtag('event', 'firm_signup')

                        navigate.push('/signup#signupForm')
                    }}>Sign Up
            </Button>
            <br/>
            <Button className="px-10 py-5 mx-auto rounded" onClick={() => {
                gtag('event', 'curious_signup')
                navigate.push('/signup')
            }}>Come learn more</Button>
        </div>
    );
}