import IntroScene, {SCENE_SIGN_IN, SCENE_SIGN_UP} from "./IntroScene";
import SignInForm from "./SignInForm";
import {ScenePlayer} from "@varymadellc/my-weed-club-component-library";
import React, {useState} from "react";
import getScenePlayerBackButtonFactory from "./ScenePlayerBackButton";
import {Frame, Button, Scene, ButtonVariantProps} from "@varymadellc/my-weed-club-component-library";
import SignUpSceneFrame1 from "./SignUpSceneFrame1";
import SignUpSceneFrame2 from "./SignUpSceneFrame2";
import SignUpSceneFrame3 from "./SignUpSceneFrame3";
import SignUpSceneFrame4 from "./SignUpSceneFrame4";
import {useSearchParams} from "next/navigation";

export const SCENE_INTRO = 'intro';

export const SCENE_WELCOME = 'welcome';

export default function AccessForm() {
    const params = useSearchParams();

    const [sceneFrameHistory, setSceneFrameHistory] = useState<{scene: string; frame: number;}[]>([]);

    const [currentScene, setCurrentScene] = useState<string>(params.get('scene') || SCENE_INTRO);

    const [currentFrame, setCurrentFrame] = useState<number>(Number(params.get('frame')) || 1);

    const [agrees, setAgrees] = useState<boolean>(false);

    const [formData, setFormData] = useState<any>({
        username: '',
        email: '',
        fullName: '',
        dateOfBirth: '',
        password: '',
        confirmPassword: '',
        agrees: false,
        zipCode: '',
    });

    //TODO - this is a hack to get the back button to work but not done.
    function recordHistory() {
        const lastHistory = sceneFrameHistory[sceneFrameHistory.length - 1];

        const newHistory = {scene: currentScene || SCENE_INTRO, frame: currentFrame || 0};

        if (!(lastHistory?.frame === currentFrame && lastHistory?.scene === currentScene)) {
            setSceneFrameHistory((currentState) => [...currentState, newHistory])
        }
    }

    const setScene = (scene?: string) => {
        // recordHistory();

        setCurrentScene(scene || SCENE_INTRO);
    }

    const setFrame = (frame: number) => {
        // recordHistory();

        setCurrentFrame(frame || 1);
    }

    const resetFormData = () => {
        setFormData({
            username: '',
            email: '',
            fullName: '',
            dateOfBirth: '',
            password: '',
            confirmPassword: '',
            agrees: false,
            zipCode: '',
        });
    }

    const ScenePlayerBackButton = getScenePlayerBackButtonFactory({setScene, setFrame});

    const setData = (key: string) => (event) => {
        if (event.hasOwnProperty('target') && event.target.hasOwnProperty('value')) {
            setFormData({
                ...formData,
                [key]: event.target.value
            });
            return;
        }

        setFormData({
            ...formData,
            [key]: event
        });
    }

    return (
        <ScenePlayer scene={currentScene} frame={currentFrame}>
            <Scene className="flex justify-center items-center" name={SCENE_INTRO}>
                <IntroScene setScene={setScene} setFrame={setFrame}/>
            </Scene>
            <Scene name={SCENE_SIGN_IN}>
                <Frame>
                    <ScenePlayerBackButton scene={SCENE_INTRO}/>
                    <SignInForm/>
                </Frame>
            </Scene>
            <Scene name={SCENE_WELCOME}>
                <Frame className="p-6">
                    <h2>Welcome New Member!</h2>
                    <br/>
                    <p>We appreciate you and are very happy to have you here.</p>
                    <p>To use our service, just click the button below and then login.</p>
                    <Button variant={'primary' as ButtonVariantProps.primary} className="w-full"
                            onClick={() => setScene(SCENE_SIGN_IN)}>Goto Login</Button>
                </Frame>
            </Scene>
        </ScenePlayer>
    )
}